import request from '@/api/request'

// 获取栅栏页app列表
export const getFenceApps = (data: any) => {
    return request({
        url: '/pagedDomainCfg/fencePage',
        method: 'get',
        params: data
    })
}
